import React from "react";
import "../css/CorporateTraining.css";
import Footer from "../components/Footer";
import ProcessFlow from "./ProcessFlow";
import CardGrid from "./CardGrid";
import emailjs from "emailjs-com";

function CorporateTraining() {
  const handleSubmit = (event) => {
    event.preventDefault();

    // Get the form element
    const form = event.target;

    // Send form data using EmailJS
    emailjs
      .sendForm(
        "service_2aaewpq",
        "template_r1zcf4w",
        form,
        "NJirMFVuxz3verfi0"
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
          form.reset();
        },
        (error) => {
          alert("An error occurred. Please try again.");
          console.log(error.text);
          form.reset();
        }
      );
  };

  return (
    <>
      <div className="full-width-container">
        <div className="left">
          <div className="text-content">
            <h1>
              Level Up Your <span className="highlight">Workforce</span>,
            </h1>
            <h1>
              Invest In Skills For a{" "}
              <span className="highlight">Future-Proof Growth</span>
            </h1>
            <p>
              By investing in your team's skills today, you're building a strong
              foundation for future success.
            </p>
            {/* <button className="download-button">Download Brochure</button> */}
          </div>
          <div className="right-form">
            <form className="enquiry-form" onSubmit={handleSubmit}>
              <h2 style={{ color: "black" }}>Enquire Now!</h2>
              <input type="text" name="name" placeholder="Name" required autoComplete="off" />
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                required
                autoComplete="off"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                required
                autoComplete="off"
              />
              <select name="course" required>
                <option value="">Select a Course</option>
                <option value="java-full-stack">Java Full Stack</option>
                <option value="python-full-stack">Python Full Stack</option>
                <option value="mern-stack">MERN Stack</option>
                <option value="data-science">Data Science</option>
                <option value="data-analytics">Data Analytics</option>
                <option value="mean-stack">MEAN Stack</option>
              </select>
              <input
                type="text"
                name="company"
                placeholder="Company Name"
                required
                autoComplete="off"
              />
              <textarea name="query" placeholder="Any Query?"></textarea>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>

      {/* Why Corporate and why with CodeWave */}
      <div className="wholeCorporateTextDiv">
        <div className="firstDiv">
          <div className="heading">
            <h2>employee powered corporate training program</h2>
            <div className="line"></div>
          </div>
          <div className="text text-muted">
            In the dynamic landscape of today’s corporate environment, ongoing
            training is essential for boosting productivity and operational
            efficiency. Our corporate training programs are designed to address
            workforce issues, enhance skill levels, and establish consistency
            within your organization, leading to improved financial outcomes.
          </div>
        </div>
        <div className="firstDiv">
          <div className="heading">
            <h2>What is Corporate Training?</h2>
            <div className="line1 line"></div>
          </div>
          <div className="text text-muted">
            Corporate Training encompasses a range of activities that utilize
            different learning techniques to boost employee performance,
            ultimately contributing to the success of the business. It’s more
            than just skill acquisition; it’s about cultivating a culture of
            ongoing development. Effective corporate training enhances
            engagement and productivity by equipping employees with the
            essential skills to perform their roles effectively and adapt to
            industry changes. At Ducat India, we tailor our training programs to
            address the specific needs of your organization and its staff,
            ensuring that the training remains engaging, interactive, and
            effective.
          </div>
        </div>
        <div className="firstDiv">
          <div className="heading">
            <h2>Why Corporate Training is Important?</h2>
            <div className="line2 line"></div>
          </div>
          <div className="text text-muted">
            Corporate training is crucial for the growth of an organization. It
            enhances individual skills and fosters an environment of ongoing
            development. By focusing on the unique requirements of your
            organization and its employees, corporate training guarantees that
            the learning experience is efficient, engaging, and productive.
          </div>
        </div>
        <div className="firstDiv">
          <div className="heading">
            <h2>What We Can in Corporate Training as an EdTech Company?</h2>
            <div className="line3 line"></div>
          </div>
          <div className="text text-muted">
            As an EdTech company, we offer a range of corporate training
            solutions, including customized programs tailored to your
            organization's needs, interactive online platforms, and immersive
            content like simulations and gamified lessons. Our services also
            include skill assessments, certifications, and detailed analytics to
            track progress and effectiveness. We provide blended learning
            options, ongoing support, and specialized training in leadership and
            compliance to ensure a comprehensive and impactful learning
            experience.
          </div>
        </div>
        <div className="firstDiv">
          <div className="heading">
            <h2>why choose codewave solution?</h2>
            <div className="line4 line"></div>
          </div>
          <div className="text text-muted">
            At CodeWave Solution, we focus on enhancing your employees' skills
            to align with your organization’s learning and development
            objectives. We are dedicated to offering customized training
            solutions that address your company’s specific needs, with a proven
            success record in delivering IT courses such as full-stack
            programming, Cloud Computing, Data Science, Machine Learning, and
            more.
          </div>
          <ProcessFlow />
        </div>
        <div className="firstDiv mb-5">
          <div className="heading">
            <h2>benefits of choosing codewave solution</h2>
            <div className="line4 line"></div>
          </div>
          <div className="text text-muted">
            <ul>
              <li>
                <strong>Personalized Training Programs:</strong> Crafted to
                address your organization’s specific needs..
              </li>
              <li>
                <strong>Seasoned Instructors:</strong> Bringing over 17 years of
                experience in corporate training.
              </li>
              <li>
                <strong>Demonstrated Success:</strong> Proven achievements in
                various IT courses and training programs.
              </li>
              <li>
                <strong>Engaging Learning:</strong> Ensuring training is
                interactive and effective.
              </li>
              <li>
                <strong>Investment in Future Success:</strong> Choosing Ducat
                India means enhancing your organization's future. Our seminars
                and training sessions are designed to elevate employees to
                expert and certified professionals, boosting their confidence
                and promoting sustainable growth
              </li>
            </ul>
          </div>
          <CardGrid />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CorporateTraining;

import React from "react";
import Footer from '../components/Footer';
import { FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import "../css/OurTeam.css";
import { AmanFace, AmanPFace, KaranFace } from "./OurTeamFaces";

const OurTeam = () => {
  return (
    <>
    <section className="team-section bg-light">
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12 text-center mb-5">
            <h2 className="section-title fw-bold">Meet Our Team</h2>
            <p className="section-subtitle">
              Our team of experts is dedicated to providing top-notch services.
            </p>
          </div>
        </div>
        <div className="row gap-5 justify-content-center">
          <div className="col-lg-4 col-md-6 team-card">
            <div className="team-member">
              <AmanPFace />
              <div className="team-content">
                <h4 className="team-name">Aman Pandey</h4>
                <p className="team-role">Founder | Corporate Trainer</p>
                <p className="bio">
                  He has over 10 years of experience in the EdTech industry and
                  is the visionary behind CodeWave Solutions.
                </p>
                <p className="skills">
                  Technical & Corporate Trainer, Leadership, Strategy,
                  Innovation
                </p>
                <div className="social-icons">
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="social-icon" />
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter className="social-icon" />
                  </a>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin className="social-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 team-card">
            <div className="team-member">
              <KaranFace />
              <div className="team-content">
                <h4 className="team-name">Karan Bhat</h4>
                <p className="team-role">CEO</p>
                <p className="bio">
                  He has over 12 years of experience in cloud computing and
                  Java.
                </p>
                <p className="skills">Java Full Stack, Cloud Computing, Trainer, Leadership, Goal Oriented</p>
                <div className="social-icons">
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="social-icon" />
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter className="social-icon" />
                  </a>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin className="social-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 team-card">
            <div className="team-member">
              <AmanFace />
              <div className="team-content">
                <h4 className="team-name">Aman Tripathi</h4>
                <p className="team-role">Web Developer | Data Analyst</p>
                <p className="bio">
                  He is the founder of <strong>CodeCrush Hub</strong>  company and has good command in web development and handles all
                  technical aspects and strategy planning for the company.
                </p>
                <p className="skills">Full Stack, Data Analytics, Power BI</p>
                <div className="social-icons">
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="social-icon" />
                  </a>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin className="social-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>
  );
};

export default OurTeam;

import React from "react";
import FirstPic from "../images/coursePerks_One.png";
import SecondPic from "../images/coursePerks_Two.png";
import ThirdPic from "../images/coursePerks_Three.png";
import FourPic from "../images/coursePerks_Four.png";

export const CoursePerksOne = () => {
  return (
    <>
      <img
        src={FirstPic}
        alt="EdTech"
        style={{width: '48px', height: '47px'}}
      />
    </>
  );
};

export const CoursePerksTwo = () => {
  return (
    <>
      <img
        src={SecondPic}
        alt="EdTech"
        style={{width: '48px', height: '47px'}}
      />
    </>
  );
};

export const CoursePerksThree = () => {
  return (
    <>
      <img
        src={ThirdPic}
        alt="EdTech"
        style={{width: '48px', height: '47px'}}
      />
    </>
  );
};

export const CoursePerksFour = () => {
  return (
    <>
      <img
        src={FourPic}
        alt="EdTech"
        style={{width: '48px', height: '47px'}}
      />
    </>
  );
};

import React from 'react';
import '../css/CardGrid.css';
import B1 from '../images/benefit1.png';
import B2 from '../images/B2.png';
import B3 from '../images/B3.png';
import B4 from '../images/B4.png';
import B5 from '../images/B5.png';
import B6 from '../images/B6.png';
import B7 from '../images/B7.png';
import B8 from '../images/B8.png';

const cardData = [
  {
    title: "Changing Administration",
    description: "Employees can quickly adapt to changes in their working atmosphere and stay aligned with company objectives.",
    icon: B1
  },
  {
    title: "Grow your skills",
    description: "We bridge the skills gap for industries, building future-ready workforces.",
    icon: B2
  },
  {
    title: "Return on Investment (ROI)",
    description: "Training increases revenue and prepares staff for leadership positions.",
    icon: B3
  },
  {
    title: "Acquire personality",
    description: "Having the skills and know-how to stand out from the crowd and finish your assignment more quickly.",
    icon: B4
  },
  {
    title: "Project-based Learning",
    description: "We combine theoretical knowledge with practical application through project-based learning.",
    icon: B5
  },
  {
    title: "Crack Certification Exams",
    description: "Most employees receiving our assistance can pass the global certification exam on the first attempt.",
    icon: B6
  },
  {
    title: "100% Assistance",
    description: "We provide constant assistance to answer your queries at any place with professional help.",
    icon: B7
  },
  {
    title: "Training Certification",
    description: "After completing the training program, you will receive a course completion certificate recognized worldwide.",
    icon: B8
  }
];

const CardGrid = () => {
    return (
      <div className="card-container">
        {cardData.map((card, index) => (
          <div className="card-item" key={index}>
            <div className="card">
              <div className="card-body text-center">
                <div className="icon-container mb-3">
                  <img src={card.icon} alt={card.title} className="card-icon" />
                </div>
                <h6 className="card-title">{card.title}</h6>
                <p className="card-text">{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

export default CardGrid;

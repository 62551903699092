// HomePage.js
import React, { useEffect, useRef } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Link } from 'react-router-dom';
import '../css/HomePage.css';
import EdTechPic from '../components/HeroSection';
import {
  CoursePerksOne,
  CoursePerksTwo,
  CoursePerksThree,
  CoursePerksFour,
} from '../components/CoursePerks';
import {
  JavaCourse,
  AnalyticsCourse,
  ScienceCourse,
  MeanCourse,
  MernCourse,
  PythonCourse,
  EnrollImageSection,
} from '../components/CourseThumbnail'; 
import ScrollingCompanies from '../components/ScrollingCompanies';
import StudentList from '../components/StudentList';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';
import Footer from '../components/Footer';

const HomePage = () => {
  // Define spring animations
  const heroSpring = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(20px)' },
    config: { duration: 1000 },
  });

  const courseSpring = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(20px)' },
    config: { duration: 1000 },
  });

  const enrollSpring = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(20px)' },
    config: { duration: 1500 },
  });

  // Refs for intersection observer
  const heroRef = useRef(null);
  const courseRef = useRef(null);
  const enrollRef = useRef(null);
  const studentRef = useRef(null);
  const happyStudentsRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1, // Trigger when 10% of the element is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add('visible');
          element.classList.remove('hidden');
          observer.unobserve(element); // Stop observing once it becomes visible
        }
      });
    }, options);

    // Copy refs to variables to avoid warnings
    const heroElement = heroRef.current;
    const courseElement = courseRef.current;
    const enrollElement = enrollRef.current;
    const studentElement = studentRef.current;
    const happyStudentsElement = happyStudentsRef.current;

    if (heroElement) observer.observe(heroElement);
    if (courseElement) observer.observe(courseElement);
    if (enrollElement) observer.observe(enrollElement);
    if (studentElement) observer.observe(studentElement);
    if (happyStudentsElement) observer.observe(happyStudentsElement);

    // Cleanup observer on component unmount
    return () => {
      if (heroElement) observer.unobserve(heroElement);
      if (courseElement) observer.unobserve(courseElement);
      if (enrollElement) observer.unobserve(enrollElement);
      if (studentElement) observer.unobserve(studentElement);
      if (happyStudentsElement) observer.unobserve(happyStudentsElement);
    };
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <div className="heroDiv">
        <animated.div ref={heroRef} style={heroSpring} className="heroSection hidden">
          <div className="content">
            <p>
              Where lines of code become pathways to <strong style={{ color: 'orange', fontStyle: 'italic'}}>success</strong> for your <strong style={{ color: 'orange', fontStyle: 'italic'}}>career</strong>.
            </p>
          </div>
          <div className="edImage">
            <EdTechPic />
          </div>
        </animated.div>
      </div>

      {/* Courses Perks */}
      <div className="coursesPerks hidden" ref={courseRef}>
        <div className="contentDiv">
          <CoursePerksOne />
          <p>Learn The Essential Skills</p>
        </div>
        <hr className='hr'/>
        <div className="contentDiv">
          <CoursePerksTwo />
          <p>Earn Certificates and Degrees</p>
        </div>
        <hr className='hr'/>
        <div className="contentDiv">
          <CoursePerksThree />
          <p>Expert Trainers</p>
        </div>
        <hr className='hr'/>
        <div className="contentDiv">
          <CoursePerksFour />
          <p>Flexible Learning</p>
        </div>
      </div>

      {/* Courses Offered */}
      <div className="coursesDiv">
        <h3>Explore Our Top Courses</h3>
        <div className="underline underline_mob"></div>
        <div className="lineOneCourse">
          <Link to="/course/java-full-stack" className="courseOneLink">
            <animated.div style={courseSpring} className="courseOne hidden">
              <JavaCourse />
              <p>JAVA FULL STACK</p>
            </animated.div>
          </Link>
          <Link to="/course/data-analytics" className="courseOneLink">
            <animated.div style={courseSpring} className="courseOne hidden">
              <AnalyticsCourse />
              <p>Data Analytics</p>
            </animated.div>
          </Link>
          <Link to="/course/data-science" className="courseOneLink">
            <animated.div style={courseSpring} className="courseOne hidden">
              <ScienceCourse />
              <p>Data Science</p>
            </animated.div>
          </Link>
        </div>  
        <div className="lineTwoCourse">
          <Link to="/course/mean-stack" className="courseOneLink">
            <animated.div style={courseSpring} className="courseOne hidden">
              <MeanCourse />
              <p>MEAN STACK</p>
            </animated.div>
          </Link>
          <Link to="/course/mern-stack" className="courseOneLink">
            <animated.div style={courseSpring} className="courseOne hidden">
              <MernCourse />
              <p>MERN STACK</p>
            </animated.div>
          </Link>
          <Link to="/course/python-full-stack" className="courseOneLink">
            <animated.div style={courseSpring} className="courseOne hidden">
              <PythonCourse />
              <p>PYTHON FULL STACK</p>
            </animated.div>
          </Link>
        </div>
      </div>

      {/* Student Working Section */}
      <div className="studentWorkingSection hidden" ref={studentRef}>
        <h3 className="learners">Our Learners Work At</h3>
        <div className="underline1 underline_mob learnline"></div>
        <p className="paragraph">
          CodeWave learners work at some of the leading and innovative organizations.
        </p>
        <ScrollingCompanies />
      </div>

      {/* Happy Students */}
      <div className="happyStudents hidden" ref={happyStudentsRef}>
        <h3 className='chs'>Our Success Stories</h3>
        <div className="underline underline_mob"></div>
        <StudentList />
      </div>

      {/* Enroll Section */}
      <animated.div ref={enrollRef} style={enrollSpring} className="enrollSection hidden">
        <div className="contentSection">
          <p>See how CodeWave Solution helps your workforce stay ahead</p>
          <p>A roadmap for readying key roles in your organization for business in the digital age</p>
          <div className="enrollContact">
            <div className="calling">
              <span style={{ fontWeight: 'bold' }}>Call</span>
              <span className="call">
                <FaPhone /> +91 78273 66246
              </span>
            </div>
            <div className="whatsapp">
              <span style={{ fontWeight: 'bold' }}>Whatsapp</span>
              <span className="call">
                <FaWhatsapp /> +91 78273 66246
              </span>
            </div>
          </div>
        </div>
        <div className="imageSection">
          <EnrollImageSection />
        </div>
      </animated.div>

      {/* Placement Partners */}
      {/* <div className="placementPartners">
        <PlacementPartners />
      </div> */}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;

import React from "react";
// import EdPic from "../images/pic1.jpeg";
import EdPic from '../images/home-edu.jpg';
import '../css/HomePage.css';

const EdTechPic = () => {
  return (
    <>
      <img
        src={EdPic}
        alt="EdTech"
        style={{ width: "380px", height: "450px" }}
        className="homeEduPic"
      />
    </>
  );
};

export default EdTechPic;

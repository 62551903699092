import React from "react";
import '../css/Jobs.css';

const Jobs = () => {
  return (
    <div className="jobs-container">
      <iframe
        src="https://optimhire.com/d/Search-job-iframe?ref_code=codewave-solution&skill=&positions=&dark_color=e6aa28&light_color=e68414"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="Job Search"
      ></iframe>
    </div>
  );
};

export default Jobs;

import React from "react";
import Footer from "../components/Footer";
import AboutReception from "./AboutImage";
import "../css/About.css";

const AboutUs = () => {
  return (
    <>
      <div className="aboutPage">
        <AboutReception />
        <div className="content">
          <section className="section mission">
            <h2>Our Mission</h2>
            <p>
              Our mission is to deliver high-quality training that equips
              students with the skills they need to be job-ready and succeed in
              the tech industry.
            </p>
          </section>
          <section className="section vision">
            <h2>Our Vision</h2>
            <p>
              Our vision is to offer top-tier training at an affordable cost,
              ensuring that quality education is accessible to all.
            </p>
          </section>
          <section className="section what-we-do">
            <h2>What We Do</h2>
            <p>
              As an EdTech company, we specialize in developing and delivering a
              wide range of educational products and services. From
              comprehensive online courses to interactive learning platforms, we
              offer solutions designed to enhance the educational journey and
              support lifelong learning.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;

// StudentList.js
import React from "react";
import Testimonial from "../components/Testimonial";
import "../css/StudentList.css";
import TestimonialOne from "../images/T1.jpeg";
import TestimonialTwo from "../images/T2.jpeg";

const testimonials = [
  {
    image: TestimonialOne,
    statement:
      "About CodeWave Solution  what should I tell it was fabulous from the beginning of our coaching the way the CWS is clarifying all the concept of the students no other teacher staff could do it. They are always available for the students for clarifying their doubt even after the coaching because of the guidance I have got an Placement in  company for the post of Graduate trainee",
  },
  {
    image: TestimonialTwo,
    statement:
      "Thank you for your exceptional guidance and support. Your teaching has had a significant impact on my understanding and growth. I truly appreciate all your hard work and dedication. Your enthusiasm and commitment have inspired me to strive for excellence. I'm thrilled to share that I’ve been placed at Plus91Labs, and I couldn't have reached this milestone without your invaluable support.",
  },
];

const StudentList = () => {
  return (
    <div className="testimonialContainer">
      {testimonials.map((testimonial, index) => (
        <Testimonial
          key={index}
          image={testimonial.image}
          statement={testimonial.statement}
        />
      ))}
    </div>
  );
};

export default StudentList;

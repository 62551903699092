import React from 'react';
import JavaPic from '../images/java.png';
import AnalyticsPic from '../images/da.png';
import DSPic from '../images/ds.png';
import MeanPic from '../images/mean.png';
import MernPic from '../images/mern.png';
import PythonPic from '../images/python.jpeg';
import EnrollImage from '../images/contacts.png';

export const JavaCourse = () => {
    return (
        <>
            <img src={JavaPic}
            alt='Courseone'
            style={{width: '47px', height: '60px'}} 
            className='courseImg'
            />
        </>
    )
}

export const AnalyticsCourse = () => {
    return (
        <>
            <img src={AnalyticsPic}
            alt='Courseone'
            style={{width: '47px', height: '60px'}} 
            />
        </>
    )
}

export const ScienceCourse = () => {
    return (
        <>
            <img src={DSPic}
            alt='Courseone'
            style={{width: '47px', height: '60px'}} 
            />
        </>
    )
}

export const MeanCourse = () => {
    return (
        <>
            <img src={MeanPic}
            alt='Courseone'
            style={{width: '47px', height: '60px'}} 
            />
        </>
    )
}

export const MernCourse = () => {
    return (
        <>
            <img src={MernPic}
            alt='Courseone'
            style={{width: '47px', height: '60px'}} 
            />
        </>
    )
}

export const PythonCourse = () => {
    return (
        <>
            <img src={PythonPic}
            alt='Courseone'
            style={{width: '47px', height: '47px', mixBlendMode: 'multiply'}} 
            />
        </>
    )
}

export const EnrollImageSection = () => {
    return (
        <>
            <img src={EnrollImage}
            alt='Courseone'
            style={{width: '357px', height: '347px', mixBlendMode: 'multiply'}} 
            />
        </>
    )
}

import React from 'react';
import '../css/ProcessFlow.css';
import processFlowImage from '../images/CorporateImages.jpeg';

const ProcessFlow = () => {
  return (
    <div className="container mt-5">
      <div className="process-flow-container">
        <img src={processFlowImage} alt="Process Flow" className="process-flow-image" />
        </div>
      </div>
  );
};

export default ProcessFlow;

import React from 'react';
import Reception from '../images/AboutUsImg.jpg';
import '../css/About.css';

const AboutReception = () => {
    return (
        <div className="image-container">
            <img src={Reception} alt="Reception company" className="background-image"/>
            <div className="overlay-text">
                <h1>Welcome to Our Reception</h1>
                <p>Providing excellent services and experiences</p>
            </div>
        </div>
    );
}

export default AboutReception;

// ScrollingCompanies.js
import React from 'react';
import '../css/ScrollingCompanies.css'; 
import LP from '../images/learningPartners.png';
import SL from '../images/Soliton.png';
import TATA from '../images/TATA.png';
import TM from '../images/TM.png';
import MS from '../images/MS.png';
import NS from '../images/ND.png';

const companies = [
  { name: "Tech Mahindra", logo: TM },
  { name: "TATA ELXSI", logo: TATA },
  { name: "Soliton", logo: SL},
  { name: "Mphasis", logo: MS },
  { name: "Learning Partners", logo: LP },
  { name: "NEUDESIC", logo: NS },
];

const ScrollingCompanies = () => {
  return (
    <div className='scrolling-container'>
      <div className='scrolling-content'>
        {companies.map((company, index) => (
          <div key={index} className='company-item'>
            <img src={company.logo} alt={`${company.name} logo`} className='company-logo' />
            <p className='company-name'>{company.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollingCompanies;

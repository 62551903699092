import React from 'react';
import Aman from '../images/at.jpeg';
import AmanP from '../images/ap2.jpeg';
import Karan from '../images/karan_bhat.jpeg';
import '../css/OurTeamFace.css';

export const AmanFace = () => {
    return (
        <>
        <img src={Aman} alt="Aman Face" className="amand-image" />
        </>
    )
}

export const AmanPFace = () => {
    return (
        <>
        <img src={AmanP} alt="Aman Face" className="amand-image" />
        </>
    )
}

export const KaranFace = () => {
    return (
        <>
        <img src={Karan} alt="Aman Face" className="amand-image" />
        </>
    )
}

import React, { useState } from "react";
import Footer from "../components/Footer";
import "../css/Contact.css";

const ContactForm = () => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    city: "",
    course: "",
    helpMessage: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function Submit(e) {
    const formEle = document.querySelector(".contact-form");
    alert('Form Data is successfully submitted');
    // e.preventDefault();
    formEle.reset();

    const formData = new FormData(formEle);
    fetch("https://script.google.com/macros/s/AKfycbw-RNO1U0OnZGe6rOexIWS4A6FXjK81xRN5w4E6R2OYl6SoZdujo7SU1EtcHg1yw5ui0Q/exec", {
      method: "POST",
      body: formData
    })
  }
  
  return (
    <>
      <div className="contact-form-container mb-5">
        <h2 className="contact-form-heading">Connect With Us</h2>
        <p className="contact-form-subheading">
          For further questions, please mail us at:{" "}
          <strong> info@codewavesolution.in</strong> or contact us by filling
          out this form.
        </p>
        <form className="contact-form" onSubmit={(e)=>Submit(e)}>
          <div className="form-row">
            <label htmlFor="name">
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="contact-form-input"
                autoComplete="off"
              />
            </label>
            <label htmlFor="email">
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="contact-form-input"
                autoComplete="off"
              />
            </label>
          </div>
          <div className="form-row">
            <label htmlFor="phone">
              Phone:
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="contact-form-input"
                autoComplete="off"
              />
            </label>
            <label htmlFor="city">
              City:
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
                className="contact-form-input"
                autoComplete="off"
              />
            </label>
          </div>
          <label htmlFor="course">
            Course:
            <select
              name="course"
              value={formData.course}
              onChange={handleChange}
              required
              className="contact-form-select"
              autoComplete="off"
            >
              <option value="" disabled>Select a course</option>
              <option value="Java Full Stack">Java Full Stack</option>
              <option value="Data Science">Data Science</option>
              <option value="Data Analytics">Data Analytics</option>
              <option value="Python Full Stack">Python Full Stack</option>
              <option value="Mean Stack">Mean Stack</option>
              <option value="Mern Stack">Mern Stack</option>
            </select>
          </label>
          <label htmlFor="helpMessage" className="mt-4">
            How CodeWave Can Help You?
            <textarea
              name="helpMessage"
              value={formData.helpMessage}
              onChange={handleChange}
              rows="4"
              required
              className="contact-form-textarea"
              autoComplete="off"
            />
          </label>
          <button type="submit" className="contact-form-button">
            Send Message
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ContactForm;

// Testimonial.js
import React from "react";
import "../css/Testimonial.css";

const Testimonial = ({ image, statement }) => {
  return (
    <div className="testimonial">
      <img src={image} alt="Testimonial" className="testimonialImage" />
      <p className="testimonialStatement">{statement}</p>
    </div>
  );
};

export default Testimonial;

import React, { useState, useRef } from "react";
import Footer from "../components/Footer";
import "../css/RegistrationOnline.css";
import emailjs from "emailjs-com";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    state: "",
    course: "",
    tenth: "",
    twelve: "",
    resume: "",
  });
  const formRef = useRef(); // Reference to the form element

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Ensure that formRef.current is indeed the form element
    if (formRef.current) {
      emailjs.sendForm(
        "service_u9i6x7h", // Your EmailJS service ID
        "template_vre3hni", // Your EmailJS template ID
        formRef.current, // Pass the form reference here
        "KTMsFirAASebOY_X6" // Your EmailJS user ID
      )
      .then(
        (result) => {
          alert("Our backend team will connect with you soon!");
          // formRef.current.reset(); 
          setFormData({
            name: "",
            email: "",
            phone: "",
            state: "",
            course: "",
            tenth: "",
            twelve: "",
            resume: "",
          });
        },
        (error) => {
          alert("An error occurred, please try again.");
        }
      );
    } else {
      console.error("Form reference is not set.");
    }
  };

  return (
    <>
      <div className="registration">
        <h2 className="heading">Join Our Courses</h2>
        <p className="subheading">
          Fill out the{" "}
          <span
            style={{ color: "orange", fontWeight: "bold", fontSize: "larger" }}
          >
            Eligibility Criteria
          </span>{" "}
          form below to get started!
        </p>
        <form
          className="form contact-form"
          ref={formRef} // Attach the form reference here
          onSubmit={handleSubmit}
        >
          <div className="horizontal-container">
            <label htmlFor="name">
              Name:
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </label>
            <label htmlFor="email">
              Email:
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </label>
          </div>
          <div className="horizontal-container">
            <label htmlFor="phone">
              Phone:
              <input
                type="tel"
                name="phone"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </label>
            <label htmlFor="state">
              State:
              <input
                type="text"
                name="state"
                id="state"
                value={formData.state}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </label>
          </div>
          <label htmlFor="course">
            Course:
            <select
              name="course"
              id="course"
              value={formData.course}
              onChange={handleChange}
              required
              autoComplete="off"
            >
              <option value="">Select a course</option>
              <option value="Java Full Stack">Java Full Stack</option>
              <option value="Data Science">Data Science</option>
              <option value="Data Analytics">Data Analytics</option>
              <option value="Python Full Stack">Python Full Stack</option>
              <option value="Mean Stack">Mean Stack</option>
              <option value="Mern Stack">Mern Stack</option>
            </select>
          </label>
          <div className="horizontal-container">
            <label htmlFor="tenth">
              High School (%):
              <input
                type="tel"
                name="tenth"
                id="tenth"
                value={formData.tenth}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </label>
            <label htmlFor="twelve">
              Intermediate (%):
              <input
                type="tel"
                name="twelve"
                id="twelve"
                value={formData.twelve}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </label>
          </div>
          <label htmlFor="resume">
            Resume Link:
            <input
              type="text"
              name="resume"
              id="resume"
              value={formData.resume}
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default RegistrationForm;

import React from 'react';
import '../css/Footer.css';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section about">
                    <h3>About Us</h3>
                    <p>
                        We are an innovative EdTech company dedicated to providing high-quality online courses to help you achieve your learning goals.
                    </p>
                </div>
                {/* <div className="footer-section links">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><a href="#courses">Courses</a></li>
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                        <li><a href="#blog">Blog</a></li>
                    </ul>
                </div> */}
                <div className="footer-section contact">
                    <h3>Contact Us</h3>
                    <p>Email: info@codewavesolution.in</p>
                    <p>Phone: +91 78273 66246</p>
                </div>
                <div className="footer-section address">
                    <h3>Our Address</h3>
                    <p>Unit 101, Oxford Towers</p>
                    <p>139, HAL Old Airport Rd</p>
                    <p>Kodihalli, Bengaluru</p>
                    <p>Karnataka 560008</p>
                </div>
                <div className="footer-section social">
                    <h3>Follow Us</h3>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/profile.php?id=100090144633535&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                        <a href="https://www.linkedin.com/company/codewave-solution/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                        <a href="https://www.instagram.com/codewave_solution/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} EdTech Company. All rights reserved.</p>
                <p>Developed By - CodeCrush Hub</p>
            </div>
        </footer>
    );
}

export default Footer;
